.MessagePanel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 70px;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 400;
  color: black;
  background-color: yellow;
  z-index: 101;

  &:empty {
    padding: 0;
    height: 0;
    line-height: 0;
  }

  .close-btn {
    position: absolute;
    display: inline-block;
    top:  10px;
    left: calc(100% - 60px);
    width: 24px;
    height: 24px;

    svg {
      display: inline-block;
      width: 24px;
      height: 24px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}


