@import "./assets/css/normalize.css";

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&family=Red+Rose:wght@300;400;500&display=swap');

:root * {

  /* fonts */

  --font-fam-main: "Noto Sans", sans-serif;
  --font-fam-deco: "Playfair Display", serif;
  
  --color-primary: #1f80ff;
  --color-link: #1f80ff;
  --color-accent: #2dffc4;

  /* color palettes */

  --color-brightest-green: #00cc00;
  --color-bright-green: #3eb93e;
  --color-darkest-blue: #000a2b;
  --color-brightest-red: crimson;
  --color-next-to-white: #f3f5f7;
  --color-neutral-lightest: #eee;
  --color-neutral-light: #ccc;
  --color-neutral-light-medium: #a0a2a6;
  --color-neutral-medium: rgb(91, 98, 110);
  --color-neutral-dark: rgb(45, 49, 59);
  --color-neutral-darkest: rgb(30, 30, 30);
  --color-next-to-black: #1f3d32;
  --color-white: white;
  --color-black: black;

  /* borders */

  --border-black: 1px solid black;
  --border-white: 1px solid white;
  --border-light: 1px solid #ccc;

  /* shadows */

  --base-shadow: 0 1px 2px 0 rgba(58, 59, 73, 0.2),
    0 3px 8px 0 rgba(58, 59, 73, 0.05);
  --max-shadow: 0 10px 25px rgba(138, 153, 165, 0.5);

}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 32;
}

:root * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  font-family: var(--font-fam-main);
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.414;
  color: var(--color-neutral-dark);
  -webkit-font-smoothing: antialiased;
}

#root {
  min-height: 100vh;
}

.app {
  width: 100%;
  font-size: 16px;
  font-size: 100%;
  font-size: 1rem;
  line-height: 1.333;
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-fam-deco);
  font-weight: 400;
  line-height: 1;
}

a {
  color: black;
  text-decoration: none;
}

.link {
  color: black;
}

.link:hover {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}


input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
