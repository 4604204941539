@import "./assets/scss/global";

.page {
  min-height: 100vh;
  background-color: #000;
  overflow: hidden;
}

.main {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 180px);

  @media (min-width: 923px) {
    min-height: calc(100vh - 271px);
  }
}

.stats {
  color: #fff;
  text-align: center;
  margin: 2rem 0;
}
