.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../assets/images/notfound.jpg');
  background-size: cover;
  background-position: center;
  color: white;

  h1 {
    font-family: var(--font-fam-deco);
    font-size: 7vh;
    font-weight: 400;
  }

  verse {
    display: block;
    font-size: 3vh;
    font-family: var(--font-fam-deco);
    // font-style: italic;
    font-weight: 400;
  }

  .mtl-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    padding: 1rem 2rem;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    font-size: 2vh;
    font-family: var(--font-fam-deco);
    font-weight: 500;
    background-image: linear-gradient(120deg, #e8c450 0%, #c0900c 100%);
    color: black;

    &:hover {
      background-color: #e8c450;
      background-image: none;
    }
  }
}
