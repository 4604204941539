.Front {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    margin: 0 auto;

    .login__input {
      height: 48px;
    }

  }
}

.vol-menu {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0.5rem auto;
  padding: 1rem 0.5rem;
  background-color: #f3f5f7;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  font-size: 0.75rem;
  font-weight: 400;

  @media (min-width: 900px) {
    max-width: 900px;
    padding: 1rem 7rem;
  }

  a {
    margin: 0 0.5rem;
  }

}

.space {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 1rem;
  padding: 1rem;
  background-color: #f3f5f7;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;

  @media (min-width: 900px) {
    max-width: 900px;
    padding: 1rem 7rem;
  }

  .toc {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        font-size: 1rem;
        margin: 0;
        padding: 0;
        line-height: 1.75;
        // text-indent: -2rem;

        &::before {
          content: '—';
          position: relative;
        }
      }

    }
  }



  h5 {
    font-size: 2.75rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 2.5rem;
    margin-top: 10rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  p {
    line-height: 1.618;
  }

  hr {
    margin: 1rem auto 2rem;
    border: 0;

    &::after {
      content: '***';
      position: relative;
      top: -1rem;
      left: -1rem;
      display: block;
      text-align: center;
      font-size: 2.125rem;
      font-family: var(--font-fam-deco);
    }
  }
}


.chunk-anchor {
  display: inline-block;
  position: relative;
  text-align: right;
  font-size: 1.25rem;

  &:hover {
    color: var(--color-primary);
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}


.lang__select {
  position: relative;
  top: 7px;
  display: inline-flex;
  margin: 0 0 1rem 1.5rem;
  padding: 0.125rem;
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase; 
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ddd;

}

.refresh__btn,
.logout__btn {
  margin: 0 0 0 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: white;
  border: 1px solid #ddd;
  color: black;
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase; 
  border-radius: 5px;


  &:hover {
    cursor: pointer;
  }
}

.login__btn {
  height: 48px;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 5px;
  background-color: crimson;
  color: white;
  font-size: 15px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}
