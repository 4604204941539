$base_shadow: 0 1px 2px 0 rgba(58, 59, 73, 0.2),
0 3px 8px 0 rgba(58, 59, 73, 0.05);
$base_shadow_2: 0 2px 7px 0 rgba(58, 59, 73, 0.2),
0 7px 14px 0 rgba(58, 59, 73, 0.1);
$base_border: 1px solid #e2e5e7;
$base_border_2: 1px solid #aaa;
$base_radius: 3px;


h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 2rem;
}

.box {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.bg {
  background-size: cover;
  background-repeat: no-repeat;
}

// buttons

.mtl-btn {
  position: relative;
  display: inline-block;
  width: fit-content;
  height: auto;
  border: 0;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  background-color: crimson;
  cursor: pointer;

  &.xs {
    padding: 4px 8px;
    font-size: 0.675rem;
  }

  &.s {
    padding: 0.4rem 1rem;
    font-size: 0.75rem;
  }

  &.m {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  &.l {
    padding: 1rem 3rem;
    font-size: 1.125rem;
  }

  &.full-width {
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
  }

  &:hover {
    color: black;
    background-color: #1f80ff;
  }

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }

  &.neutral {
    background-color: var(--color-neutral-light);
    color: black;

    &:hover {
      color: white;
      background-color: var(--color-brightest-red);
    }
  }

  &.hollow {
    color: var(--color-neutral-medium);
    border: 1px solid var(--color-neutral-medium);
    background-color: white;

    &:hover {
      background-color: var(--color-neutral-medium);
      color: white;
    }
  }

  &:hover {
    color: white;
    background-color: var(--color-brightest-red);
  }

  &.prima {
    background-color: black;
    color: white;

    &.bordered {
      border: 1px solid black;
    }

    &:hover {
      color: white;
      background-color: var(--color-brightest-red);
    }
  }

  &.alt {
    background-color: aquamarine;
    color: black;

    &:hover {
      background-color: #000;
      color: white;
    }

  }

  &.inverted {
    background-color: black;

    &:hover {
      background-color: var(--color-brightest-red);
    }
  }
}

.hide {
  display: none;
}
